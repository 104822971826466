const messages = {
  'v.username.required': '用户名不能为空',
  'v.password.required': '密码不能为空',
  'v.validcode.required': '验证码不能为空',
  'v.input.required': '输入值不能为空',
  'v.email.required': '邮箱不能为空',
  'v.select.required': '选择值不能为空',
  'v.attachment.required': '附件不能为空',
  'v.attachmentMp3.required': '回访录音不能为空',
  'v.region.parentid.required': '请选择父节点区域',
  'v.input.max6': '文本必须为6个字符',
  'v.input.max9': '文本不能超过9个字符',
  'v.input.max10': '文本不能超过10个字符',
  'v.input.max16': '文本不能超过16个字符',
  'v.input.max17': '文本不能超过17个字符',
  'v.input.max18': '文本不能超过18个字符',
  'v.input.max19': '文本不能超过19个字符',
  'v.input.max20': '文本不能超过20个字符',
  'v.input.max25': '文本不能超过25个字符',
  'v.input.max35': '文本不能超过35个字符',
  'v.input.max40': '文本不能超过40个字符',
  'v.input.max50': '文本不能超过50个字符',
  'v.input.max100': '文本不能超过100个字符',
  'v.input.max200': '文本不能超过200个字符',
  'v.input.max250': '文本不能超过250个字符',
  'v.input.max300': '文本不能超过300个字符',
  'v.input.max500': '文本不能超过500个字符',
  'v.input.max1000': '文本不能超过1000个字符',
  'v.input.max2000': '文本不能超过2000个字符',
  'v.input.max2000NoNull': '文本不能为空且不能超过2000个字符',
  'v.phone.formaterror': '电话格式错误',
  'v.process.approvalremark.required': '审批意见不能为空！',
  'v.process.confirmremark.required': '处理意见不能为空！',
  'v.process.rejectreason.required': '请在备注说明中输入拒绝理由！',
  'v.process.returnreason.required': '请在备注说明中输入退回理由！',
  'v.image.maxsize.1m': '请选择小于 1M 的图片',
  'v.image.lrz.5m': '图片大于5M，已进行压缩处理',
  'v.image.lrz.5m.error': '图片大于5M，压缩处理失败',
  'v.file.maxsize.5m': '附件大于5M，上传失败',
  'v.notfindrequest': '无效单据',
  'v.process.remark.required': '备注说明不能为空！',
  'v.replacement.larequestdetailselected.required':
    '请选择需要补换货的子服务单！',
  'v.replacement.larequestdetailselected.agreeChecks':
    '不同仓库只能选择一条进行免费补货',
  'v.materialdata.materialnorequired': '物料号不能为空！',
  'v.dndata.deliverynotenorequired': '发货单号不能为空！',
  'v.dndata.deliverynoteitemnorequired': '发货单上项目号不能为空！',
  'v.larequestdetaildata.receivedqtyoverpackingqty':
    '实际收到的正确物料数量不能大于装箱清单数量！',
  'v.materialdata.vendorcodeunequal': '补换货产品所属工厂不一致，请检查！',

  'v.materialdata.shipfromrequired': '请先选择出货地点！',
  'v.materialdata.unabbinternalvendor': '非ABB内部工厂，请检查！',
  'v.remark.isnotnull': '备注不能为空',
  'v.process.approvalattachment.required': '附件不能为空！',
  'v.process.approvalattachment.atleastone': '请至少上传一个附件！',
  'v.larequest.shippedlocationcodeunselect': '请先选择发货地点！',
  'v.larequest.noInputShippedNo': '单价为空，请先填写获取发货单号！',
  'v.larequest.servicetypeselect':
    '原厂标签正确，实物与标签不符（包含包装内少附件等），请填写质量问题服务单',
  'v.onsiteexpenses.rejectWarning':
    '拒绝将关闭整个服务单，请注意！',
  'v.dndata.ztpderror': '请输入对应的工厂直发发货单号！',
  'v.dndata.ztpderror2': '当前发货单是工厂直发的，请检查！',
  'v.dndata.1004ztpderror': '请输入对应的上海进口仓发货单号！',
  'v.dndata.plantcodeerror': '当前发货单号与发货地点不匹配，请重新输入！',
  'v.dndata.nodata': '产品信息不能为空！',
  'v.replacement.closereplenishitemselected.required':
    '请先选择需要关闭换货的子服务单！',
  'v.input.spcode': '服务商编码限制使用五个大写字母。',
  'v.tsrequestdetail.createreplacement.unmatchconditions':
    'ABB客户或客户需要至少填写一个才能申请补换货，请检查！',
  'v.lareplacementrequest.qtymustover0': '补换货产品数量必须大于0，请检查！',
  'v.tsreplacementrequest.atleast1attachment': '请上传至少一个附件！',
  'v.podata.ponumbernorequired': 'PO#不能为空！',
  'v.podata.poitemnorequired': 'Item#不能为空！',
  'v.internaldeliverydifferencesrequest.paidcurrency.required': '赔付金额币种不能为空，请先选择！',
  'v.podata.plantunmatch': '当前PO#与Plant不匹配，请重新输入！',
  'v.internaldeliverydifferencesrequest.plantunselect': '请先选择Plant！',
  'v.abroaddeliverydifferencesrequest.plantunselect': '请先选择Plant！',
  'v.instoragedeliverydifferencesrequest.plantunselect': '请先选择Plant！',  
  'v.process.closereason.required': '请在备注说明中输入关闭理由！',
  'v.instoragedeliverydifferencesrequest.dataisnull': '数据不能为空!',
  'v.materialdata.repairrequest.vendorcodeunequal': '返厂产品所属工厂不一致，请检查！',
  'v.tsreturnscrap.add.selectisnull': '请至少选择一个退货单号！',

};
export default messages;
