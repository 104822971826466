import http from './http';

export function Get(id) {
  return http.get('/TSReturnScrap/Get?id='+id);
}

export function getToDoPagingList(query) {
  return http.post('/TSReturnScrap/getToDoPagingList', query);
}
export function GetReportPagingList(query) {
  return http.post('/TSReturnScrap/GetReportPagingList', query);
}
export function GetExportPagingList(query) {
  return http.post('/TSReturnScrap/GetExportPagingList', query);
}

export function GetReturnRequestList(query) {
  return http.post('/TSReturnScrap/GetReturnRequestList', query);
}
export function GetTSRetrunItemsById(tsReturnDetailIDs) {
  return http.post('/TSReturnScrap/GetTSRetrunItemsById', tsReturnDetailIDs);
}

export function del(id) {
  return http.delete('/TSReturnScrap/Delete?id='+ id);
}

export function Save(data) {
  return http.post('/TSReturnScrap/Save', data);
}
export function Submit(data) {
  return http.post('/TSReturnScrap/Submit', data);
}
export function TSMApproval(data) {
  return http.post('/TSReturnScrap/TSMApproval', data);
}
export function WHMApproval(data) {
  return http.post('/TSReturnScrap/WHMApproval', data);
}
export function WHApproval(data) {
  return http.post('/TSReturnScrap/WHApproval', data);
}
export function GetToDoCount() {
  return http.post('/TSReturnScrap/GetToDoCount');
}

