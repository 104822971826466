const messages = {
  'menu.home': '首页',
  'menu.newrequest': '创建服务单',
  'menu.newtsrequest': '质量问题类',
  'menu.tsrequest': '质量问题服务',  
  'menu.newtsrequest.update': '编辑服务单',
  'menu.newtsrequest.process': '处理服务单',
  'menu.newtsrequest.detail': '服务单详情',
  'menu.newlarequest': '错发破损类',
  'menu.larequest': '错发破损服务',
  'menu.pendingtask': '处理服务',
  'menu.pendingtsrequests': '质量问题服务',
  'menu.pendinglarequests': '错发破损服务',
  'menu.nostarttasks': '待接受服务',
  'menu.ongoingtask': '待处理服务',
  'menu.replacementrequests': '补换货管理',//补换货管理
  'menu.tsreplacementrequests': '质量问题补换货',
  'menu.lareplacementrequests': '错发破损补换货',
  'menu.returnrequests': '退货管理',
  'menu.nostartreturntsrequests': 'TS未退货列表',
  'menu.pendingreturnrequests': 'TS退货列表',
  'menu.lapendingreturnrequests': 'LA退货列表',
  'menu.tsreturnscraprequest': 'TS退货报废列表',
  'menu.newtsretrunscraprequest': 'TS退货报废新建',
  'menu.processtsretrunscraprequest': 'TS退货报废单审批',
  'menu.returnscraprequestdetail': 'TS退货报废单审批',

  'menu.tsreturnscrapreport':'TS退货报废单报表',
  'menu.pendingfactoryclaims': '索赔管理',
  'menu.factoryclaims': '待索赔列表',
  'menu.laclaimreport': '索赔列表',
  'menu.pendingonsitecheckrequests': '作业许可管理',
  'menu.pendingonsitecheckrequests.detail': '作业许可单据处理',
  'menu.onsitesupportreportpage': '作业许可及完工检查',
  'menu.onsitesupportreportpage.detail': '作业许可单据详情',
  'menu.onsitesupportexpensemanagement': '费用报销管理',
  'menu.gonsiteexpenses': '未申请报销列表',
  'menu.pendingonsiteexpenses': '申请费用报销',
  'menu.unclaimsupportlist': '费用报销列表',
  'menu.expensesprocess': '审批费用',
  'menu.tsonsitesupportexpensereport': '费用报销列表',
  'menu.tsonsitesupportexpensereport.detail': '费用报销详情',
  'menu.report': '报表',
  'menu.larequestreport': '错发破损列表',
  'menu.wdrequestreport': '错发服务列表',
  'menu.dmrequestreport': '破损服务列表',
  'menu.lareplacement': '错发破损补换货服务',
  'menu.lareplacementreport': '错发破损补换货服务报表',
  'menu.tsonsitesupportreportpage': '免费补换货服务报表',
  'menu.lareturn': '客户退货',
  'menu.lareturnreport': '客户退货报表',
  'menu.dmrequestreportpage': '索赔报表',
  'menu.tsrequestreport': '质量问题服务简表',
  'menu.tsrequestdetailreport': '质量问题服务详表',
  'menu.tsreplacement': '质量服务补换货',
  'menu.tsreplacementreport': '质量问题服务补换货报表',
  'menu.tsreturn': '退货',
  'menu.tsreturnreport': '质量问题服务退货报表',
  'menu.factoryclaimreportpage': '现场服务单报表',
  'menu.systemmanagement': '系统管理',
  'menu.masterdata': '基础数据管理',
  'menu.region': '业务区域管理',
  'menu.workflow': '业务流程管理',
  'menu.emailtemplate': '邮件模板管理',
  'menu.smstemplate': '短信模板管理',
  'menu.newtsreplacementrequests': '提交补换货申请',
  'menu.updatetsreplacementrequests': '编辑补换货申请',
  'menu.tsreplacementrequestdetail': '补换货申请单详情',
  'menu.processtsreplacementrequest': '处理补换货申请',
  'menu.processlareplacementrequest': '处理补换货申请',
  'menu.newreturnrequests': '发起退货',
  'menu.returnrequestdetail': '退货单详情',
  'menu.updatereturnrequests': '编辑退货单',
  'menu.processreturnrequests': '处理退货单',
  'menu.larequestdetail': '错发破损单详情',
  'menu.updatelarequest': '编辑错发破损单',
  'menu.processlarequest': '处理错发破损单',
  'menu.startcheck': '作业许可',
  'menu.closecheck': '完工检查',
  'menu.larequestitemdetail': '子服务单详情',
  'menu.processlarequestdetail': '处理子服务单',
  'menu.nostartreplacementlarequests': '未补换货列表',
  'menu.replenishreturnrequests': '完善退货单',
  'menu.spmasterdata': '服务商管理',
  'menu.tsonsitesupportreport': '现场服务单列表',
  'menu.onsitesupportreport': '现场服务报表',
  'menu.sparrowtemplate': 'Sparrow',
  'menu.processgoodsclaimdetail': '处理索赔',
  'menu.detailgoodsclaimdetail': '索赔详情',
  'menu.asynctask': '后台任务管理',
  'menu.nocreatesaporderreplacementrequests': '未创建换货单列表',
  'menu.cboluserdashboard': '我的服务单',
  'menu.producttypeclassify': '产品类型分类管理',
  'menu.producttype': '产品类型管理',
  'menu.problemtype': '故障类型管理',
  'menu.tsreturnsetting': '质量服务单退货设置',  
  'menu.newinternaldeliverydifferencesrequest': '国内收货差异',
  'menu.newinternaldeliverydifferencesrequest.update': '编辑服务单',
  'menu.newinternaldeliverydifferencesrequest.detail': '服务单详情',
  'menu.pendinginternaldeliverydifferencesrequests': '国内收货差异',
  'menu.internaldeliverydifferencesrequestreport': '国内收货差异列表',
  'menu.processinternaldeliverydifferencesrequest': '处理服务单',
  'menu.internaldeliverydifferencesdetailrequestreport': '国内收货差异列表',
  'menu.internaldeliverydifferencesdetailrequest.detail': '服务单详情',
  'menu.newabroaddeliverydifferencesrequest': '国外收货差异',
  'menu.newabroaddeliverydifferencesrequest.update': '编辑服务单',
  'menu.newabroaddeliverydifferencesrequest.detail': '服务单详情',
  'menu.pendingabroaddeliverydifferencesrequests': '国外收货差异',
  'menu.abroaddeliverydifferencesrequestreport': '国外收货差异列表',
  'menu.processabroaddeliverydifferencesrequest': '处理服务单',
  'menu.abroaddeliverydifferencesdetailrequestreport': '国外收货差异列表',
  'menu.abroaddeliverydifferencesdetailrequest.detail': '服务单详情',
  'menu.newinstoragedeliverydifferencesrequest': '在库库存差异',
  'menu.newinstoragedeliverydifferencesrequest.update': '编辑服务单',
  'menu.newinstoragedeliverydifferencesrequest.detail': '服务单详情',
  'menu.pendinginstoragedeliverydifferencesrequests': '在库库存差异',
  'menu.instoragedeliverydifferencesrequestreport': '在库库存差异列表',
  'menu.processinstoragedeliverydifferencesrequest': '处理服务单',
  'menu.instoragedeliverydifferencesdetailrequestreport': '在库库存差异列表',
  'menu.instoragedeliverydifferencesdetailrequest.detail': '服务单详情',
  'menu.carrier': '运输商管理',
  'menu.pendinginternaldeliverydifferencesclaimrequests': '国内收货差异索赔',
  'menu.internaldeliverydifferencesclaimrequestreport': '国内收货差异索赔列表',
  'menu.internaldeliverydifferencesclaimrequestreport.detail': '服务单详情',
  'menu.processinternaldeliverydifferencesclaimrequest': '处理服务单',
  'menu.deliverydifferencessummaryreport': '差异总列表',
  'menu.differencesreport': '收货差异',
  'menu.specification':'说明书',
  'menu.tssphazardhome':'服务商隐患报告申报',
  'menu.newtssphazardrequest': '服务商隐患报告申报',
  'menu.tssphazardreport':'服务商隐患报表',
  'menu.newtsrepairrequests':'返厂维修单',
  'menu.tsrepairreport':'返修单报表',
  'menu.tsrepairrequest':'返修单',
  'menu.tsrepairrequestdetail':'返修单详情',
  'menu.pendingtsrepairrequest':'返修单列表',
  'menu.processtsrepairrequest':'返修单据处理',
};
export default messages;
