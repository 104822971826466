import React, { useState, useEffect } from 'react';
import {
  Menu,
  Divider,
  Layout,
  notification,
  Badge,
  Dropdown,
  Space,
  MenuItem,
} from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { UserOutlined, ExportOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectTopMenus,
  selectMenus,
  selectUserType,
  selectUserEmail,
  selectName,
  signOut,
} from '../../features/auth/authSlice';
import { Link } from 'react-router-dom';
import './LayoutDefault.less';
import * as LoginService from '@/api/login';
import * as TSRequestService from '@/api/tsrequest';
import * as TSReplacementRequestService from '@/api/tsreplacementrequest';
import * as TSReturnRequestService from '@/api/tsreturnrequest';
import * as LARequestService from '@/api/larequest';
import * as LARequestDetailService from '@/api/larequestdetail';
import * as LAReplacementRequestService from '@/api/lareplacementrequest';
import * as ReplacementRequestService from '@/api/replacementrequest';
import * as OnsiteSupportService from '@/api/onsitesupport';
import * as OnsiteExpensesService from '@/api/onsiteexpenses';
import * as GoodsclaimService from '@/api/goodsclaim';
import * as InternalDeliveryDifferencesrequestService from '@/api/internaldeliverydifferencesrequest';
import * as AbroadDeliveryDifferencesrequestService from '@/api/abroaddeliverydifferencesrequest';
import * as InStorageDeliveryDifferencesrequestService from '@/api/instoragedeliverydifferencesrequest';
import * as InternalDeliveryDifferencesClaimrequestService from '@/api/internaldeliverydifferencesclaimrequest';
import * as TSRepairRequestService from '@/api/tsrepairrequest';
import * as TSReturnScrapService from '@/api/tsreturnscraprequest';

import eventBus from '@/utils/eventBus';
import { FormattedMessage, useIntl } from 'react-intl';
import { handleError } from '@/utils/handleError';
import { logout } from '@/api/auth';
function LayoutDefault(props) {
  let year = new Date().getFullYear();
  const [dDV, setDDV] = useState(false);
  const location = useLocation();
  const topmenus = useSelector(selectTopMenus);
  const leftmenus = useSelector(selectMenus);
  const [selectTopMenuKey, setSelectedTopMenuKey] = useState('');
  const [selectTopMenuID, setSelectedTopMenuID] = useState('');
  const [openLeftMenuKey, setOpenedLeftMenuKey] = useState('');
  const [selectLeftMenuKey, setSelectedLeftMenuKey] = useState('');
  const { Header, Content, Footer, Sider } = Layout;

  useEffect(() => {
    var topMenuCode = localStorage.getItem('topMenuCode');
    if (topMenuCode) {
      var index0 = topmenus.findIndex(x => x.menuCode === topMenuCode);
      if (index0 > -1) {
        setSelectedTopMenuID(topmenus[index0].id);
      }
      setSelectedTopMenuKey(topMenuCode);
    }
    var leftMenuCode = localStorage.getItem('leftMenuCode');
    // console.log(leftMenuCode);
    // console.log(leftmenus);
    // if (leftMenuCode) {
    //   var openMenuCodes = [];
    //   var index = leftmenus.findIndex(x => x.menuCode === leftMenuCode);
    //   if (index === -1) {
    //     for (var i = 0; i < leftmenus.length; i++) {
    //       var item = leftmenus[i];
    //       if (item.children) {
    //         index = item.children.findIndex(x => x.menuCode === leftMenuCode);
    //         if (index > -1) {
    //           openMenuCodes.push(item.menuCode);
    //         } else {
    //           for (var j = 0; j < item.children.length; j++) {
    //             var sitem = item.children[j];
    //             if (sitem.children) {
    //               index = sitem.children.findIndex(
    //                 x => x.menuCode === leftMenuCode
    //               );
    //               if (index > -1) {
    //                 openMenuCodes.push(item.menuCode);
    //                 openMenuCodes.push(sitem.menuCode);
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }

    //   setOpenedLeftMenuKey(openMenuCodes.toString());
    //   setSelectedLeftMenuKey(leftMenuCode);
    // }
    var openMenuCodes = [];
    for (var i = 0; i < leftmenus.length; i++) {
      var item = leftmenus[i];
      if (item.children) {
          for (var j = 0; j < item.children.length; j++) {
          var sitem = item.children[j];
          if (sitem.children) {
              openMenuCodes.push(item.menuCode);
              openMenuCodes.push(sitem.menuCode);
          }
        }
      }
    }
    setOpenedLeftMenuKey(openMenuCodes.toString());
    setSelectedLeftMenuKey(leftMenuCode);
    var topMenuCode = localStorage.getItem('topMenuCode');
    var leftMenuCode = localStorage.getItem('leftMenuCode');
    if (1 === 1 || !topMenuCode || !leftMenuCode) {
      eventBus.addListener('selectedTopMenu', function (val) {
        setSelectedTopMenuKey(val);
        localStorage.setItem('topMenuCode', val);
        let index = topmenus.findIndex(x => x.menuCode === val);
        if (index > -1) {
          setSelectedTopMenuID(topmenus[index].id);
        }
      });
      eventBus.addListener('selectedLeftMenu', function (val) {
        setSelectedLeftMenuKey(val);
        localStorage.setItem('leftMenuCode', val);
        var openMenuCodes = [];
        var index = leftmenus.findIndex(x => x.menuCode === val);
        if (index === -1) {
          for (var i = 0; i < leftmenus.length; i++) {
            var item = leftmenus[i];
            if (item.children) {
              index = item.children.findIndex(x => x.menuCode === val);
              if (index > -1) {
                openMenuCodes.push(item.menuCode);
              } else {
                for (var j = 0; j < item.children.length; j++) {
                  var sitem = item.children[j];
                  if (sitem.children) {
                    index = sitem.children.findIndex(x => x.menuCode === val);
                    if (index > -1) {
                      openMenuCodes.push(item.menuCode);
                      openMenuCodes.push(sitem.menuCode);
                    }
                  }
                }
              }
            }
          }
        }

        setOpenedLeftMenuKey(openMenuCodes.toString());
      });
    }
    initBadgeCount(); //初始化角标
  });

  function initBadgeCount() {
    getBadgeCount('pendingtsrequests'); //质量问题服务
    getBadgeCount('nostarttasks'); //错发破损服务-待接受服务
    getBadgeCount('ongoingtask'); //错发破损服务-待处理服务
    getBadgeCount('nostartreplacementlarequests'); //补换货管理-未补换货列表
    getBadgeCount('tsreplacementrequests'); //补换货管理-质量问题补换货
    getBadgeCount('lareplacementrequests'); //补换货管理-错发破损补换货
    getBadgeCount('nostartreturntsrequests'); //退货管理-未退货列表
    getBadgeCount('pendingreturnrequests'); //退货管理-质量问题退货列表
    getBadgeCount('lapendingreturnrequests'); //退货管理-错发破损退货列表
    getBadgeCount('pendingonsitecheckrequests'); //作业许可管理
    getBadgeCount('pendingonsiteexpenses'); //未申请报销列表
    getBadgeCount('unclaimsupportlist'); //费用报销列表
    getBadgeCount('pendingfactoryclaims'); //索赔列表
    getBadgeCount('nocreatesaporderreplacementrequests'); //补换货股那里-未创建SPA补换货单列表
    getBadgeCount('pendinginternaldeliverydifferencesrequests'); //国内收货差异
    getBadgeCount('pendingabroaddeliverydifferencesrequests'); //国外收货差异
    getBadgeCount('pendinginstoragedeliverydifferencesrequests'); //收货入库异常
    getBadgeCount('pendinginternaldeliverydifferencesclaimrequests'); //国内收货差异索赔
    getBadgeCount('pendingtsrepairrequest'); //返修列表
    getBadgeCount('tsreturnscraprequest'); //返修列表

  }

  /**
   * 加载角标数据
   * @param {*} code 编码
   */
  function getBadgeCount(code) {
    var domObj = document.getElementById(code);
    if (domObj == null) {
      return;
    }
    if (code === 'pendingtsrequests') {
      //质量问题服务
      TSRequestService.getToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'nostarttasks') {
      //错发破损服务-待接受服务
      LARequestService.getToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'ongoingtask') {
      //错发破损服务-待处理服务
      LARequestDetailService.getToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'nostartreplacementlarequests') {
      //补换货管理-未补换货列表
      LARequestDetailService.getUnReplacementCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'tsreplacementrequests') {
      //补换货管理-质量问题补换货
      TSReplacementRequestService.getToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'lareplacementrequests') {
      //补换货管理-错发破损补换货
      LAReplacementRequestService.getToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'nostartreturntsrequests') {
      //退货管理-未退货列表
      TSReturnRequestService.getUnReturnCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'nocreatesaporderreplacementrequests') {
      //补换货股那里-未创建SPA补换货单列表
      ReplacementRequestService.getToCreateSAPOrderCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'pendingreturnrequests') {
      //退货管理-退货列表
      TSReturnRequestService.getToDoCount('tsreturn').then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'lapendingreturnrequests') {
      //退货管理-退货列表
      TSReturnRequestService.getToDoCount('lareturn').then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'pendingonsitecheckrequests') {
      //作业许可管理
      OnsiteSupportService.getToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'pendingonsiteexpenses') {
      //未申请报销列表
      OnsiteExpensesService.GetNotExpensesTodoPagingCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'unclaimsupportlist') {
      //费用报销列表
      OnsiteExpensesService.GetPendingCountAsync().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'pendingfactoryclaims') {
      //索赔列表
      GoodsclaimService.GetToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'pendinginternaldeliverydifferencesrequests') {
      //国内收货差异
      InternalDeliveryDifferencesrequestService.getToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'pendingabroaddeliverydifferencesrequests') {
      //国外收货差异
      AbroadDeliveryDifferencesrequestService.getToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'pendinginstoragedeliverydifferencesrequests') {
      //收货入库异常
      InStorageDeliveryDifferencesrequestService.getToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'pendinginternaldeliverydifferencesclaimrequests') {
      //国内收货差异索赔
      InternalDeliveryDifferencesClaimrequestService.getToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if (code === 'pendingtsrepairrequest') {
      //返修
      TSRepairRequestService.getToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
    if(code==='tsreturnscraprequest'){
       //返修
       TSReturnScrapService.GetToDoCount().then(res => {
        domObj.style.display = res > 0 ? 'inline' : 'none';
        domObj.innerText = res;
      });
    }
  }

  function handleTopMenuClick(e) {
    setSelectedTopMenuKey(e.key);
    localStorage.setItem('topMenuCode', e.key);
    let index = topmenus.findIndex(x => x.menuCode === e.key);
    if (index > -1) {
      setSelectedTopMenuID(topmenus[index].id);
    }
  }

  function handleLeftMenuClick(e) {
    setSelectedLeftMenuKey(e.key);
    localStorage.setItem('leftMenuCode', e.key);
    var _topmenucode = localStorage.getItem('topMenuCode');
    if (_topmenucode && _topmenucode === 'home') {
      setTimeout(() => {
        initBadgeCount(); //初始化角标
      }, 500);
    }
  }

  function handleSetBadgeCount() {
    var _topmenucode = localStorage.getItem('topMenuCode');
    if (_topmenucode && _topmenucode === 'home') {
      setTimeout(() => {
        initBadgeCount(); //初始化角标
      }, 500);
    }
  }

  return (
    <Layout
      className="customlayout"
      style={{
        minWidth: /mobile/i.test(navigator.userAgent) ? '1800px' : '1560px',
      }}
    >
      <Header className="header">
        {/* <div className="stripe">
          <span className="stripe-logo"></span>
          <span className="stripe-name">Cuscare Portal</span>
        </div> */}
        {/* <div className="header-menu">
          
        </div> */}
        <div className="logo"></div>
        <div className="header-name">服务e速达</div>
        <div className="header-bar">
          <NavTopMenubar
            selectedKey={selectTopMenuKey}
            handleTopMenuClick={handleTopMenuClick}
          />
          <Toolbar dDVBtn={() => setDDV(true)} />
        </div>
      </Header>
      <Content
        style={{
          padding: '0 50px',
          paddingTop: '16px'
        }}
      >
        <Layout
          className="content-layout"
          style={{
            padding: '24px 0',
            background: '#fff',
            minWidth: '1480px',
            minHeight:
              window.screen.width * window.devicePixelRatio >= 3840 &&
              window.devicePixelRatio === 1
                ? window.innerHeight * (window.devicePixelRatio / 1.3) - 155
                : window.innerHeight * window.devicePixelRatio - 155,
          }}
        >
          <Sider width={240} style={{ background: '#fff' }}>
            <NavLeftMenubar
              selectedKey={selectLeftMenuKey}
              selectTopMenuID={selectTopMenuID}
              openLeftMenuKey={openLeftMenuKey}
              handleLeftMenuClick={handleLeftMenuClick}
              handleSetBadgeCount={handleSetBadgeCount}
            />
          </Sider>
          <Content style={{ padding: '0 24px', minHeight: 280 }}>
            <div>{props.children}</div>
          </Content>
        </Layout>
      </Content>
      <Footer style={{ textAlign: 'center' }}>&copy; {year} ABB<span style={{color:'red'}}>(本应用请使用Edge或Chrome浏览器打开)</span></Footer>
    </Layout>
  );
}

export default LayoutDefault;

/**
 * 头部菜单
 * @param {*} param0
 * @returns
 */
function NavTopMenubar(props) {
  const menus = useSelector(selectTopMenus);
  const intl = useIntl();

  function handleTopMenuClick(e) {
    props.handleTopMenuClick(e);
  }

  function getNavItems(items) {
    let navItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      let navitem = {
        key: item.menuCode,
        label: intl.formatMessage({ id: 'menu.' + item.menuCode }),
      };
      navitem.label = (
        <Link to={item.url}>
          {intl.formatMessage({ id: 'menu.' + item.menuCode })}
        </Link>
      );
      navItems.push(navitem);
    }
    return navItems;
  }

  return (
    <Menu
      className="topmenus"
      selectedKeys={[props.selectedKey]}
      onClick={handleTopMenuClick}
      mode="horizontal"
      items={getNavItems(menus)}
    />
  );
}

/**
 * 左侧菜单
 * @param {*} param0
 * @returns
 */
function NavLeftMenubar(props) {
  const topmenus = useSelector(selectTopMenus);
  const menus = useSelector(selectMenus);
  const [openMenuKey, setOpenedMenuKey] = useState([]);
  const intl = useIntl();
  let selectedTopMenuID =
    topmenus.length > 0
      ? props.selectTopMenuID || topmenus[0].Id
      : props.selectTopMenuID;
  let defaultOpenMenuKey = props.openLeftMenuKey.split(',');

  function handleLeftMenuClick(e) {
    props.handleLeftMenuClick(e);
  }

  function handleSetBadgeCount() {
    props.handleSetBadgeCount();
  }

  function getNavItems(items) {
    let navItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.parentID !== selectedTopMenuID) {
        continue;
      }
      let navitem = {
        key: item.menuCode,
        label: intl.formatMessage({ id: 'menu.' + item.menuCode }),
      };
      if (item.children && item.children.length > 0) {
        navitem.children = [];
        for (let j = 0; j < item.children.length; j++) {
          const x = item.children[j];
          let navcitem = {
            key: x.menuCode,
            label: (
              <Link to={x.url === undefined ? '' : x.url}>
                {intl.formatMessage({ id: 'menu.' + x.menuCode })}
                <span id={x.menuCode} className="badgeCount">
                  0
                </span>
              </Link>
            ),
          };
          if (x.children && x.children.length > 0) {
            navcitem.children = [];
            for (let k = 0; k < x.children.length; k++) {
              const y = x.children[k];
              let navccitem = {
                key: y.menuCode,
                label: (
                  <Link to={y.url === undefined ? '' : y.url}>
                    {intl.formatMessage({ id: 'menu.' + y.menuCode })}
                    <span id={y.menuCode} className="badgeCount">
                      0
                    </span>
                  </Link>
                ),
              };
              navcitem.children.push(navccitem);
            }
          }
          navitem.children.push(navcitem);
        }
      } else {
        navitem.label = (
          <Link to={item.url}>
            {intl.formatMessage({ id: 'menu.' + item.menuCode })}
            <span id={item.menuCode} className="badgeCount">
              0
            </span>
          </Link>
        );
      }
      navItems.push(navitem);
    }
    return navItems;
  }

  return (
    <Menu
      className="leftmenus"
      mode="inline"
      openKeys={openMenuKey.length > 0 ? openMenuKey : defaultOpenMenuKey}
      selectedKeys={[props.selectedKey]}
      onClick={handleLeftMenuClick}
      onOpenChange={openKeys => {
        console.log(openKeys);
        setOpenedMenuKey(openKeys);
        handleSetBadgeCount();
      }}
      items={getNavItems(menus)}
    />
  );
}

/**
 * 右侧操作按钮
 * @param {*} props
 * @returns
 */
function Toolbar(props) {
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const userEmail = useSelector(selectUserEmail);
  const name = useSelector(selectName);
  const userItems = [
    {
      label: '修改用户信息',
      key: 'edituser',
    },
  ];
  const handleUserMenuClick = key => {
    if (key === 'edituser') {
      var url = window.ENV_CFG.edituser_link;
      url = url.replace('{0}', userEmail);
      console.log(url);
      window.open(url, '_blank');
    }
  };

  const handleLogout = () => {
    logout().then(() => {
      dispatch(signOut());
      if (userType === 0) {
        window.location.href = window.ENV_CFG.signout;
      } else {
        LoginService.logout()
          .then(res => {
            if (res.tag === 1) {
              window.location.reload();
            } else {
              notification.error({
                message: res.message,
                placement: 'top',
                duration: 0,
              });
            }
          })
          .catch(e => {
            handleError(e);
          });
      }
    })
  };

  return (
    <div className="app-header-bar-controls">
      {/* <div className="control-item">
        <LanguageSetting className="lang" />
      </div> */}
      {name && name.length > 0 && (
        <React.Fragment>
          {/* <div className="control-item">
            <UserOutlined /> {name}
          </div> */}
          {userType === 2 && (
            <div className="control-item">
              <Dropdown
                overlay={
                  <Menu
                    items={userItems}
                    onClick={({ key, domEvent }) => {
                      domEvent.stopPropagation();
                      handleUserMenuClick(key);
                    }}
                  ></Menu>
                }
              >
                <div>
                  <UserOutlined /> {name}
                </div>
              </Dropdown>
            </div>
          )}
          {userType !== 2 && (
            <div className="control-item">
              <UserOutlined /> {name}
            </div>
          )}
          <Divider type="vertical" />
          <div className="control-item" onClick={handleLogout}>
            <ExportOutlined />
            <FormattedMessage id="layout.signout" />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
