const messages = {
  'm.upload.tips':
    '支持JPG、PNG、BMP等图片格式，MP4、MPEG等视频格式，及PDF、Word文档等文件，单个文件不超过50M，最多上传10个附件',
  'm.upload.max35tips':
    '支持JPG、PNG、BMP等图片格式，MP4、MPEG等视频格式，及PDF、Word文档等文件，建议最多上传35个附件',
  'm.upload.tips1':
    '支持JPG、PNG、BMP等图片格式，MP4、MPEG等视频格式，及PDF、Word文档等文件，单个文件不超过50M',
  'm.upload.Mp3tips':'1.支持WAV、MP3、AMR, AAC, FLAC等音频格式，单个文件不超过50M，最多上传10个附件',
  'm.upload.Mp3tips2':'2.通常一个服务单要求提供一个回访录音文件，文件名的命名要求：TS报销单号+公司简称.文件类型。如：TS231109489ZBPNDEC淄博普诺.WAV。',
  'region.add.success': '区域信息添加成功！',
  'region.edit.success': '区域信息编辑成功！',
  'region.delete.success': '区域信息删除成功！',
  'producttypeclassify.add.success': '产品类型分类信息添加成功！',
  'producttypeclassify.edit.success': '产品类型分类信息编辑成功！',
  'producttypeclassify.delete.success': '产品类型分类信息删除成功！',
  'masterdata.add.success': '基础数据添加成功！',
  'masterdata.edit.success': '基础数据编辑成功！',
  'masterdata.delete.success': '基础数据删除成功！',
  'workflow.add.success': '流程添加成功！',
  'workflow.edit.success': '流程编辑成功！',
  'workflow.delete.success': '流程删除成功！',
  'tsrequest.submit.success': '服务申请单提交成功！',
  'tsrequest.save.success': '服务申请单保存成功！',
  'tsrequest.update.success': '服务申请单修改成功！',
  'tsrequest.delete.success': '服务单删除成功！',
  'tsrequest.changeserviceoffice.success': '服务地城市修改成功！',
  'tsrequest.assignts.success': 'TS指派成功！',
  'tsrequest.assignsp.success': 'sp指派成功！',
  'tsrequest.reassignts.success': 'TS重新指派成功！',
  'tsrequest.tsclain.success': 'TS认领成功！',
  'tsrequest.rejecttsclain.success': '服务单拒绝成功！',
  'tsrequest.completeservice.success': '申请单服务完成成功！',
  'tsrequestdetail.delete.success': '故障产品删除成功！',
  'tsrequestdetail.solve.success': '故障产品成功设置解决！',
  'tsrequestdetail.complete.success': '故障产品成功设置完成！',
  'tsrequestdetail.savetechnicaladvice.success': '技术建议提交成功！',
  'emailtemplate.add.success': '邮件模板新建成功！',
  'emailtemplate.edit.success': '邮件模板编辑成功！',
  'emailtemplate.delete.success': '邮件模板删除成功！',
  'tsreturnsetting.add.success': '质量服务单退货设置新建成功！',
  'tsreturnsetting.edit.success': '质量服务单退货设置编辑成功！',
  'tsreturnsetting.delete.success': '质量服务单退货设置删除成功！',
  'tsreplacementrequest.selectproduct.noselected': '请选择您要选择的产品！',
  'tsreplacementrequest.items.empty': '补换货成品信息不能为空！',
  'tsreplacementrequest.items.more':
    '出货地点选择Vendor，只允许提交1条补换货产品信息!',
  'tsreplacementrequest.submit.success': '补换货单提交成功！',
  'tsreplacementrequest.save.success': '补换货单保存成功！',
  'tsreplacementrequest.process.success': '单据审批处理成功！',
  'tsreplacementrequest.delete.success': '补换货单删除成功！',
  'tsreplacementrequest.cancel.success': '补换货单撤销成功！',
  'tsreturnrequest.submit.success': '退货单提交成功！',
  'tsreturnrequest.save.success': '退货单保存成功！',
  'tsreturnrequest.process.success': '单据审批处理成功！',
  'tsreturnrequest.delete.success':'退货单删除成功！',
  'onsitesupport.startcheck.saveclose': '单据保存成功！',
  'onsitesupport.startcheck.submitclose':
    '提交成功，页面将跳转至质量问题处理列表。',
  'onsitesupport.closecheck.saveclose': '单据保存成功！',
  'onsitesupport.closecheck.submitclose':
    '提交成功，页面将跳转至质量问题处理列表。',
  'tsreturnscraprequest.process.success': 'TS退货作废单审批成功！',
  'larequest.save.success': '错发破损单保存成功！',
  'larequest.submit.success': '错发破损单提交成功！',
  'larequest.process.success': '单据审批处理成功！',
  'larequest.process.return.success': '单据退回处理成功！',
  'larequest.process.submit.success': '单据重新提交处理成功！',

  'larequest.delete.success': '错发破损单删除成功！',
  'larequest.cancel.success': '错发破损单取消成功！',
  'tsrequest.cancel.success': '质量服务单取消成功！',
  'lareplacementrequest.submit.success': '补换货单提交成功！',
  'lareplacementrequest.save.success': '补换货单保存成功！',
  'lareplacementrequest.delete.success': '补换货单删除成功！',
  'lareplacementrequest.cancel.success': '补换货单撤销成功！',
  'lareplacementrequest.needwdreturn.tips':
    '金额>1K CNY（HKABB，金额>1k HKD）客户需退回错发产品',
  'lareplacementrequest.needdmreturn.tips':
    '金额>1K CNY（HKABB，金额>1k HKD）客户需退回破损产品',
  'v.lareplacementrequest.isneedwdreturn.required':
    '金额已超过1k CNY或HKD，请勾选需要客户退回错发产品！',
  'v.lareplacementrequest.isneeddmreturn.required':
    '金额已超过1k CNY或HKD，请勾选需要客户退回破损产品！',
  'lareturnrequest.process.success': '退货单处理成功！',
  'smstemplate.add.success': '短信模板新建成功！',
  'smstemplate.edit.success': '短信模板编辑成功！',
  'smstemplate.delete.success': '短信模板删除成功！',
  'sparrowtemplate.save.success': '数据保存成功！',
  'system.leavewithoutsaving.title': '系统提示',
  'system.leavewithoutsaving.content':
    '如果离开当前页面，未保存的数据将丢失，您确认要离开吗？',
  'onsitesupprot.rejected.sucress': '服务单拒绝成功！',
  'onsitesupprot.approved.sucress': '服务单审批成功！',
  'onsitesupprot.delete.title': '是否删除该作业许可单据？',
  'onsiteexpenses.rejected.title': '拒绝费用报销',
  'onsiteexpenses.onpennew.tip': '如果提交费用报销申请，则不能再提交现场服务！',
  'onsiteexpenses.rejected.content':
    '是否拒绝费用报销，如果拒绝则无法重新提单！',
  'tsrequest.todo.hsestartcheck.title': '本人是否参加现场服务',
  'tsrequest.todo.hsestartcheck.content': '本人是否参加现场服务',
  'tsrequest.todo.spassignconfirm.title': '是否接受指派',
  'tsrequest.todo.spassignconfirm.content':
    '点击【确定】表示贵司接受指派，并将安排工程师进行现场服务',
  'tsrequest.todo.spassignconfirm.success': '接受成功！',

  'tsrequest.claimtsrequest.confirmmessage':
    '服务单认领后，服务所在地将无法变更，确认是否继续？',
  'tsrequest.assignts.tipmessage':
    '请先确认服务所在地是否正确，指派TS后将无法变更',
  'lareturnrequest.rejectreturn.confirmmessage':
    '若贵司确认拒绝退货，此单行项错发/破损服务将被关闭，无法再为您提供补货服务，确认是否继续？',
  'lareplacementrequest.createsaporder.success':
    'SAP补换货单创建成功【订单号：{0}】！',
  'tsreplacementrequest.createsaporder.success':
    'SAP补换货单创建成功【订单号：{0}】！',
  'lareplacementrequest.closereplenish.success': '关闭补货成功！',
  'producttype.add.success': '产品类型添加成功！',
  'producttype.edit.success': '产品类型编辑成功！',
  'producttype.delete.success': '产品类型删除成功！',
  'tsrequest.detail.hasapplied': '您所选择的故障产品已发起补换货，无法删除！',
  'tsrequest.draftdata.unexist': '您所选择的草稿单不存在，请重新创建！',
  'tsreplacementrequest.draftdata.unexist':
    '您所选择的草稿单不存在，请重新创建！',
  'tsreturnrequest.draftdata.unexist': '您所选择的草稿单不存在，请重新创建！',
  'tsreplacementrequest.closereturn.attachmenttip':
    '附件为审批邮件，附件个数为1到5个',
  'tsreplacementrequest.closereturn.success': '质量服务单补换货退货关闭成功！',
  'tsreplacementrequest.closereplacement.success': '质量服务单补换货关闭成功！',
  'lareplacementrequest.closereplacement.success':
    '错发破损服务单补换货关闭成功！',
  'internaldeliverydifferencesrequest.save.success': '国内收货差异单保存成功！',
  'internaldeliverydifferencesrequest.delete.success': '国内收货差异单删除成功！',
  'internaldeliverydifferencesrequest.submit.success': '国内收货差异单提交成功！',
  'internaldeliverydifferencesrequest.process.save.success': '审批结果保存成功！',
  'internaldeliverydifferencesrequest.process.success': '单据审批处理成功！',
  'abroaddeliverydifferencesrequest.save.success': '国外收货差异单保存成功！',
  'abroaddeliverydifferencesrequest.delete.success': '国外收货差异单删除成功！',
  'abroaddeliverydifferencesrequest.submit.success': '国外收货差异单提交成功！',
  'abroaddeliverydifferencesrequest.process.save.success': '审批结果保存成功！',
  'abroaddeliverydifferencesrequest.process.success': '单据审批处理成功！',
  'instoragedeliverydifferencesrequest.save.success': '收货入库异常单保存成功！',
  'instoragedeliverydifferencesrequest.delete.success': '收货入库异常单删除成功！',
  'instoragedeliverydifferencesrequest.submit.success': '收货入库异常单提交成功！',
  'instoragedeliverydifferencesrequest.process.save.success': '审批结果保存成功！',
  'instoragedeliverydifferencesrequest.process.success': '单据审批处理成功！',
  'carrier.add.success': '运输商添加成功！',
  'carrier.edit.success': '运输商编辑成功！',
  'carrier.delete.success': '运输商删除成功！',
  'process.return.cofirmmessage': '您确认要退回当前服务单吗？',
  'process.submit.cofirmmessage': '您确认要重新提交当前服务单吗？',

  'process.reject.cofirmmessage': '您确认要拒绝当前服务单吗？',
  'process.close.cofirmmessage': '您确认要关闭当前服务单吗？',
  'internaldeliverydifferencesclaimrequest.process.success': '单据审批处理成功！',
  'process.saveplansolution.success': '预计解决方案保存成功！',
  'tsrepairrequest.items.empty': '返修成品信息不能为空！',
  'tsrepairrequest.items.more' : '返修只允许提交1条补换货产品信息!',
  'tsrepairrequest.delete.success': '返修单据删除成功！',
  'tsrepairrequest.save.success':'返修单保存成功!',
  'tsrepairrequest.submit.success':'返修单保存成功!',
  'tsrepairrequest.cancel.success':'返修单撤销成功!',
  'tsrepairrequest.process.success':'返修单审批成功!',
  'tssphazard.process.submit':'服务商隐患单提交成!',
  'lareturn.submit.tip':'如退货物料号和数量与Cuscare退货产品信息存在差异，请点击“拒绝退货”，重新提交错发破损服务。',
  'lareturn.submit.tip2':'一旦您点击“提交”按钮，ABB将生成换货订单。如实际退货产品及数量与换货订单内容不符，ABB将保留追溯权。',
  'tsrepairrequest.selectproduct.noselected':'请选择您要选择的产品！',
  'tsrepairrequest.selectproduct.selectedmore':'只能选择一个产品！',
};
export default messages;
